@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --z-header: 1000;
  --z-modal-overlay: 2000;
  --z-modal: 2001;
  --z-notification: 3000;
  --z-dropdown: 1500;
  --z-fixed-element: 900;
  --z-content: 1;
}

/* Event Detail Container */
.event-detail-container {
  @apply w-full;
}

@media (min-width: 1024px) {
  .event-detail-container {
    @apply max-w-4xl mx-auto;
  }
}

/* CKEditor Z-index fixes */
.ck.ck-toolbar {
  z-index: 100000 !important;
}

.ck.ck-balloon-panel {
  z-index: 100001 !important;
}

.ck-link-form, .ck-link-actions {
  z-index: 100002 !important;
}

/* Rich Text Editor Styles */
.ck-content {
  @apply min-h-[200px];
}

/* Event Description Styles */
.event-description {
  @apply text-gray-700 leading-relaxed;
}

.event-description a,
.ck-content a {
  @apply text-blue-600 underline hover:text-blue-800;
}

.event-description ul,
.ck-content ul {
  @apply list-disc pl-5 my-2;
}

.event-description ol,
.ck-content ol {
  @apply list-decimal pl-5 my-2;
}

.event-description li,
.ck-content li {
  @apply ml-4 mb-1;
}

.event-description p,
.ck-content p {
}

.event-description p:last-child,
.ck-content p:last-child {
  @apply mb-0;
}

.event-description h1,
.ck-content h1 {
  @apply text-2xl font-bold mb-4;
}

.event-description h2,
.ck-content h2 {
  @apply text-xl font-bold mb-3;
}

.event-description h3,
.ck-content h3 {
  @apply text-lg font-bold mb-2;
}

.event-description blockquote,
.ck-content blockquote {
  @apply pl-4 border-l-4 border-gray-300 italic my-4;
}

.event-description strong,
.ck-content strong {
  @apply font-bold;
}

.event-description em,
.ck-content em {
  @apply italic;
}

.ck.ck-editor__editable_inline {
  @apply p-4 border border-gray-200 rounded-md;
}

.ck.ck-editor__editable_inline:focus {
  @apply border-blue-500 outline-none ring-2 ring-blue-200;
}

/* Dropdown Utilities */
@layer utilities {
  .dropdown-auto-position {
    @apply absolute z-[var(--z-dropdown)] mt-1;
    max-width: calc(100vw - 2rem);
    top: var(--button-bottom, 100%);
  }

  .dropdown-mobile-center {
    @apply fixed z-[var(--z-dropdown)] left-1/2 transform -translate-x-1/2;
    width: 90vw;
    max-width: 300px;
    top: calc(var(--button-bottom) + 0.5rem);
  }
}

.event-description img,
.ck-content img {
  @apply max-w-full h-auto my-4 rounded-lg;
}

.event-description figure,
.ck-content figure {
  @apply my-4;
}

.event-description figure img,
.ck-content figure img {
  @apply my-0;
}

.event-description figcaption,
.ck-content figcaption {
  @apply text-sm text-gray-600 text-center mt-2;
}

/* Table Styles */
.event-description table,
.ck-content table {
  @apply w-full my-4 border-collapse;
}

.event-description th,
.ck-content th {
  @apply bg-gray-100 font-semibold;
}

.event-description td,
.event-description th,
.ck-content td,
.ck-content th {
  @apply border border-gray-300 p-2 text-left;
}

.event-description tr:nth-child(even),
.ck-content tr:nth-child(even) {
  @apply bg-gray-50;
}

.event-description tr:hover,
.ck-content tr:hover {
  @apply bg-gray-100;
}

/* Make tables responsive */
.event-description .table-container,
.ck-content .table-container {
  @apply overflow-x-auto max-w-full;
}
